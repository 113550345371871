var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"elevation-12 mx-auto pb-5 pt-0",attrs:{"max-width":"400"}},[_c('v-toolbar',{attrs:{"dark":"","color":"user_primary"}},[_c('v-toolbar-title',{staticClass:"text-sm-h5 mx-auto"},[_vm._v("Web Forms Examples")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.LB_HALF
              }
            }}},[_vm._v("LB HALF")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.LB_FULL
              }
            }}},[_vm._v("LB FULL")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.ML_REGULAR
              }
            }}},[_vm._v("ML REGULAR")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.ML_CUTTING
              }
            }}},[_vm._v("ML CUTTING")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.FS_ONLINE
              }
            }}},[_vm._v("FS ONLINE")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'stylist-certification',
              params: {
                stylistId: _vm.stylistAccountId,
                applicationId: _vm.applicationId,
                certification: _vm.StylistsCertifications.FS_IN_PERSON
              }
            }}},[_vm._v("FS IN-PERSON")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'Salons'
            }}},[_vm._v("Admin")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'SalonHome'
            }}},[_vm._v("Salon Home Page")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'SalonMessages',
              params: { salonId: _vm.salonId }
            }}},[_vm._v("Salon Messages Dashboard")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{ name: 'userType' }}},[_vm._v("Web Form 1: Find a salon")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'GreatNews',
              params: {
                consumerId: _vm.consumerId,
                salonId: _vm.salonId
              }
            }}},[_vm._v("Web Form 2: Great News")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'NoSalonFound',
              params: { consumerId: _vm.emptyConsumerId }
            }}},[_vm._v("Web Form 2.1: No Salon Found")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'NewConsumerContact',
              query: { source: _vm.ContactMessageSources.example_page }
            }}},[_vm._v("Web Form 2.2: Contact Page")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'Welcome'
            }}},[_vm._v("Web Form 2.3: Welcome")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'NewInquiry',
              params: {
                consumerId: _vm.newInquiryConsumerId,
                salonId: _vm.newInquirySalonId
              }
            }}},[_vm._v("Web Form 3: New Inquiry")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","to":{
              name: 'DiscountPage',
              params: { consumerId: _vm.consumerId, salonId: _vm.salonId }
            },"dark":"","right":""}},[_vm._v("Web Form 4: Discount Page")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{ name: 'FindSalon' }}},[_vm._v("Web Form 5: Salon Sign Up")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{ name: 'PendingSalons' }}},[_vm._v("Web Form 5.1: Pending Salons")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'Communication',
              params: {
                consumerId: _vm.newInquiryConsumerId,
                salonId: _vm.newInquirySalonId
              }
            }}},[_vm._v("Web Form 6: Communication")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'AdminCommunication',
              params: {
                consumerId: _vm.newInquiryConsumerId,
                salonId: _vm.newInquirySalonId
              }
            }}},[_vm._v("Web Form 6.1: Communication")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'Agreement'
            }}},[_vm._v("Web Form 7: Agreement")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'Certification'
            }}},[_vm._v("Certification")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'Unsubscribe',
              params: { consumerId: this.consumerId }
            }}},[_vm._v("Web Form 8: Unsubscribe")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"primary","dark":"","right":"","to":{
              name: 'SendEmails'
            }}},[_vm._v("Web Form 9: Send E-Mails")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'CCPA'
            }}},[_vm._v("CCPA Compliance")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'WF11_Stylists',
              query: { system: _vm.SystemOptions.LASERBEAMER }
            }}},[_vm._v("WF11: Stylists (NANO)")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'WF11_Stylists',
              query: { system: _vm.SystemOptions.FREESTYLER }
            }}},[_vm._v("WF11: Stylists (FREESTYLER)")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'WF11_Stylists',
              query: { system: _vm.SystemOptions.MICROLINES }
            }}},[_vm._v("WF11: Stylists (MICROLINES)")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'GlobalContactPage',
              params: { source: 'fb-ig-ad-ml' }
            }}},[_vm._v("CONTACT PAGE: MICROLINES")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'GlobalContactPage',
              params: { source: 'fb-ig-ad-lb' }
            }}},[_vm._v("CONTACT PAGE: NANO")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'GlobalContactPage',
              params: { source: 'fb-ig-ad-fs' }
            }}},[_vm._v("CONTACT PAGE: FREESTYLER")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-caption text-sm-button",attrs:{"width":"100%","color":"user_primary","dark":"","right":"","to":{
              name: 'GlobalContactPage',
              params: { source: 'fb-ig-ad-ta' }
            }}},[_vm._v("CONTACT PAGE: TAPES")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }