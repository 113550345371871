<template>
  <v-container>
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="elevation-1 mx-auto mt-3">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>License</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pa-5 pt-0 d-flex flex-column">
        <v-card-text class="text-left"
          >Upload a valid cosmetology license to expedite the approval
          process</v-card-text
        >
        <v-btn
          rounded
          @click="handleUpload"
          color="user_primary"
          class="white--text mx-auto mb-2"
          small
          max-width="100"
          >Upload</v-btn
        >
        <v-alert v-if="imgError" color="error" dense class="mt-2" outlined
          >Oops! The size limit for images is 5.0 MB. Reduce the file size and
          try again.</v-alert
        >
        <v-file-input
          v-show="false"
          ref="fileInput"
          label="Upload"
          :rules="pictureRules"
          prepend-icon="mdi-camera"
          @change="handlePictureChange"
          v-model="picture"
        ></v-file-input>
        <v-progress-circular
          class="mx-auto"
          v-if="pictureLoading"
          indeterminate
          color="dark"
        ></v-progress-circular>
        <!-- pdf -->
        <v-card-text class="text-center" v-else-if="answer && isPdf">
          <a :href="answer" target="_blank">Open PDF</a>
        </v-card-text>
        <!-- image -->
        <v-img
          class="mx-auto"
          v-else-if="answer"
          text-align="center"
          :src="answer"
          :lazy-src="answer"
        ></v-img>

        <v-checkbox
          v-if="!answer"
          class="ml-3"
          v-model="doItLater"
          label="I'll do it later"
        ></v-checkbox>

        <v-card-actions>
          <v-btn
            block
            color="user_primary"
            class="white--text mt-2"
            rounded
            :disabled="disableBtn"
            @click="handleSubmit"
            :loading="this.$store.state.applications.loading"
          >
            Submit</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        userInfo: {},
        doItLater: false,
        picture: null,
        pictureLoading: false,
        imgError: null,
        pictureRules: [
          value =>
            !value ||
            value.size < 5000000 ||
            'Image size should be less than 5 MB!'
        ],
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        screen: 'stylist-license',
        field: 'cosmetologyLicenseFile',
        answer: ''
      }
    },
    computed: {
      showingFirstTime() {
        return !this.$store.state.applications.application.system.length
      },
      nextScreen() {
        // 1. no system -> this is first license screen
        if (this.showingFirstTime) {
          return 'stylist-system1'
        }
        // 2. has system -> this is second license screen, at the end
        return 'stylist-final-status'
      },
      disableBtn() {
        return !this.answer && !this.doItLater
      },
      isPdf() {
        return this.answer?.includes('.pdf')
      }
    },
    methods: {
      ...mapActions([
        'addLicense',
        'setIsValid',
        'setCurrentScreen',
        'setNextScreen'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      handleUpload() {
        this.$refs.fileInput.$refs.input.click()
      },
      async handlePictureChange() {
        if (this.picture?.size < 5000000) {
          const file = this.picture
          const form = new FormData()
          form.append('file', file)
          this.pictureLoading = true

          await this.addLicense({
            form,
            id: this.$route.params.applicationId
          })

          if (
            !this.$store.state.applications.error &&
            this.$store.state.applications.application[this.field]
          ) {
            this.answer = this.$store.state.applications.application[this.field]
            this.handleNextScreen()
            this.setIsValid(true)
          }

          this.pictureLoading = false
          this.imgError = null
        } else {
          this.imgError = 'Image size should be less than 5 MB!'
        }
      },
      async handleSubmit() {
        if (!this.$store.state.auth.error) {
          this.handleNextScreen()
          this.setIsValid(true)
          this.$emit('next')
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>
